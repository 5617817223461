<template>
    <div>
        <app-page-header></app-page-header>
        <v-container  grid-list-xl class="box">
            <v-form id="form_rfid_user" ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg10 xl8>
                        <v-card id="form_card">
                            <v-card-title>
                                <v-icon left>mdi-account</v-icon>
                                    <span class="subtitle-1 font-weight-regular application--card--title">Podatki o uporabniku</span>
                                    <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('user-data')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>

                            <!-- osebni podatki -->
                            <v-card-text v-show="card1">

                                <!-- backend validation messages -->
                                <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                                <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                                <!-- tip uporabnika -->
                                <v-select
                                    :items="userTypes"
                                    label="Tip uporabnika"
                                    item-text="name"
                                    item-value="uuid"
                                    v-model="user_type"
                                    persistent-hint
                                    :rules="user_type_rules"
                                ></v-select>

                                <!--<div v-if="!apartma" style="min-height:5px"></div>-->

                                <!--
                                <v-select
                                    v-if="!apartma"
                                    v-model="company_id"
                                    :items="companies"
                                    label="Izberi podjetje"
                                    clearable
                                    deletable-chips
                                    item-value="id"
                                    item-text="name"
                                    dense
                                    no-data-text="Ni podatkov za prikaz"
                                    :rules="(!apartma) ? company_rules : []"
                                ></v-select>
                                -->


                                <v-text-field v-if="abonent()"
                                    v-model="name"
                                    label="Ime in priimek / naziv"
                                    required
                                    clearable
                                    :rules="(abonent()) ? name_rules : []"
                                ></v-text-field>

                                <!-- polja za goste -->
                                 <!--
                                <v-text-field v-if="!abonent()"
                                    v-model="reservation"
                                    label="Rezervacija"
                                    required
                                    clearable
                                    :rules="(!abonent()) ? reservation_rules : []"
                                ></v-text-field>
                                <v-text-field v-if="!abonent()"
                                    v-model="room_number"
                                    label="Številka sobe"
                                    required
                                    clearable
                                ></v-text-field>
                                -->

                                <!--
                                <v-text-field
                                    v-model="address"
                                    label="Naslov"
                                    required
                                    clearable
                                ></v-text-field>

                                <v-text-field
                                    v-model="town"
                                    label="Kraj"
                                    required
                                    clearable
                                ></v-text-field>
                                 -->   
                                <!--
                                 <v-text-field v-if="abonent()"
                                    v-model="phone_number"
                                    label="Telefonska številka"
                                    required
                                    clearable
                                    type="number"
                                ></v-text-field>
                                -->

                                <v-text-field v-if="abonent()"
                                    v-model="email"
                                    label="E-mail"
                                    required
                                    clearable
                                    :rules="(abonent() && (email !== null && email.trim() !== '') ) ? email_rules : []"
                                ></v-text-field>
                                
                                <!--
                                <v-switch
                                    v-if="apartma"
                                    v-model="email_notification"
                                    :label="`Obvesti uporabnika o prijavi v sistem: ${emailNotificationLabel}`"
                                    color="secondary"
                                    hide-details
                                ></v-switch>

                                <div v-if="apartma" style="min-height:15px"></div>
                                -->

                                <br>
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="Opombe"
                                v-model="note"
                                counter
                                clearable
                                ></v-textarea>
                            </v-card-text>
                            
                            <!-- stanje uporabnika-->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-state-machine</v-icon>
                                <v-toolbar-title class="body-2">Stanje uporabnika</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('user-state')">
                                    <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card4">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="user_state"
                                                :label="`Uporabnik je v dolini: ${userStateParking}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                    <div style="min-height:7px;"></div>
                                </v-container>
                            </v-card-text>

                            <!-- registrske številke -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Registrske številke</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card8 = !card8">
                                    <v-icon>{{ card8 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card8">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex x12 align-content-center>
                                            <p>Registrske številke (do 4 reg. številke):</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="registration_number_1"
                                                label="Registrska št. 1"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="registration_number_2"
                                                label="Registrska št. 2"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="registration_number_3"
                                                label="Registrska št. 3"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="registration_number_4"
                                                label="Registrska št. 4"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="registration_numbers_enabled"
                                                :label="`Dovoli vstop z uporabo registrskih številk: ${userRegistrationNumbersEnabled}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="truck"
                                                :label="`${truckLable}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>


                             <!-- telefonske številke -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cellphone</v-icon>
                                <v-toolbar-title class="body-2">Telefonske številke</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card9 = !card9">
                                    <v-icon>{{ card9 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card9">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex x12 align-content-center>
                                            <p>Telefonske številke (do 2 tel. številke):</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_1"
                                                label="Telefonska št. 1"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_2"
                                                label="Telefonska št. 2"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_3"
                                                label="Telefonska št. 3"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_4"
                                                label="Telefonska št. 4"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_5"
                                                label="Telefonska št. 5"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_6"
                                                label="Telefonska št. 6"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_7"
                                                label="Telefonska št. 7"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                                            <v-text-field
                                                v-model="phone_number_8"
                                                label="Telefonska št. 8"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="phone_numbers_enabled"
                                                :label="`Dovoli vstop z uporabo telefonskih številk: ${usePhoneNumbersEnabled}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            

                            <!-- rfid kartica -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-file-document-outline</v-icon>
                                <v-toolbar-title class="body-2">RFID kartica</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('rfid-card')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card2">
                                <v-container grid-list-xl text-x-center align-center >
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_id"
                                                label="ID kartice"
                                                required
                                                :disabled="!card_id_input_enabled"
                                                ref="cardId"
                                                :rules="getCardNumber ? card_id_rules : []"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Serijska številka kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_number"
                                                label="Serijska št. kartice"
                                                required
                                                :disabled="!card_number_input_enabled"
                                                ref="cardNumber"
                                                @keyup.enter.native="RfidNr"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Kartica blokirana</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-switch
                                                v-model="card_blocked"
                                                :label="`Blokiraj kartico: ${cardBlocked}`"
                                                color="secondary"
                                                value="1"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="storeUserLoading " :loading="scanRfidLoading" color="success" @click="enableRfidCardInput"><v-icon small left>mdi-wifi</v-icon>Klikni tukaj za skeniranje</v-btn>
                                            &nbsp;&nbsp;
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="scanRfidLoading ||storeUserLoading" color="error" @click="removeRfidCard"><v-icon small left>mdi-close</v-icon>Odstrani kartico</v-btn>
                                            <!-- &nbsp;&nbsp;
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="scanRfidLoading ||storeUserLoading || card_entrance_limit == null" color="info" @click="cardEntranceQuotaReset()"><v-icon small left>mdi-refresh</v-icon>Ponastavi števec vstopov</v-btn>-->
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                            <v-divider></v-divider>
                           

                            <!-- datumska omejitev -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-file-document-outline</v-icon>
                                <v-toolbar-title class="body-2">Datumska omejitev</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('date-restrictions')">
                                    <v-icon>{{ card5 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card5">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Veljavnost dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Datum od"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Datum do"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center v-if="$vuetify.breakpoint.lgAndUp">
                                            <!--<v-subheader class="pb-5 pl-0"><strong>Nastavi veljavnost dovolilnice</strong></v-subheader>-->
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="info" @click="restrictionTime1YearFromNow()"><v-icon small left>mdi-calendar-month</v-icon>Veljavnost 1 leto</v-btn>
                                            &nbsp;&nbsp;
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="info" @click="restrictionTime5YearsFromNow()"><v-icon small left>mdi-calendar-month</v-icon>Veljavnost 5 let</v-btn>
                                            &nbsp;&nbsp;
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="info" @click="restrictionTime10YearsFromNow()"><v-icon small left>mdi-calendar-month</v-icon>Veljavnost 10 let</v-btn>
                                            
                                        </v-flex>
                                    </v-layout>
                                </v-container> 
                            </v-card-text>
                            
                            <!-- omejitve -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cancel</v-icon>
                                <v-toolbar-title class="body-2">Tip omejitve</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('restrictions')">
                                    <v-icon>{{ card6 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card6">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Izberi tip omejitve</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                                :items="restrictionTypes"
                                                label="Tip omejitve"
                                                item-text="name"
                                                item-value="id"
                                                v-model="restriction_id"
                                                persistent-hint
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <!--<v-subheader class="pt-7 pl-0"><strong>Omejen dostop</strong></v-subheader>-->
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-switch
                                                v-model="restricted_access"
                                                :label="`${restrictedAccessLabel}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                            <!-- casovne omejitve -->
                           <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-file-document-outline</v-icon>
                                <v-toolbar-title class="body-2">Časovna omejitev</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('time-restrictions')">
                                    <v-icon>{{ card7 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card7">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Časovna omejitev</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-switch
                                                v-model="use_time_restriction"
                                                :label="`Upoštevaj časovno omejitev: ${useTimeRestriction}`"
                                                color="primary"
                                                value="1"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs6 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Začetek časovne omejitve (izberi uro)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex  xs12 md8 align-content-center>
                                            <v-menu
                                                ref="start_time_menu"
                                                v-model="start_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="restriction_start_time"
                                                    label="Začetni čas"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                    :disabled="!use_time_restriction"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="start_time_menu"
                                                v-model="restriction_start_time"
                                                
                                                @click:minute="$refs.start_time_menu.save(restriction_start_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                     <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Konec časovne omejitve (izberi uro)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-menu
                                                ref="end_time_menu"
                                                v-model="end_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="restriction_end_time"
                                                    label="Končni čas"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                    :disabled="!use_time_restriction"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="end_time_menu"
                                                v-model="restriction_end_time"
                                                full-width
                                                @click:minute="$refs.end_time_menu.save(restriction_end_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs6 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Časovna omejitev zadrževanja (minute)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="restriction_time_period"
                                                label="Čas v minutah"
                                                required
                                                prepend-icon="mdi-timer-sand"
                                                type="number"
                                                :disabled="!use_time_restriction"
                                                clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container> 
                            </v-card-text>

                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn block outlined style="margin-bottom:10px;" @click="submitForm()" color="success" :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>{{ btnSubmitFormTitle }}</v-btn>
                                <v-btn block outlined style="" @click="clearForm()" color="warning"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                            <v-card-text v-else>
                                <v-btn @click="submitForm()" color="success" text :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>{{ btnSubmitFormTitle }}</v-btn>
                                <v-btn @click="clearForm()" color="warning" text><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { formatDate, randomPinNumber, getCurrentDate, getDay2Digits, getMonth2Digits } from '@/helpers/utilities.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {

    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: true,
        form: true,
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        card5: true,
        card6: true,
        card7: true,
        card8: true,
        card9: true,
        loading: false,
        loading2: false,
        scanRfidLoading: false,
        scanRfidLoading2: false,
        storeUserLoading: false,
        storeUserLoading2: false,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,
        start_time_menu:false,
        end_time_menu:false,
        name: null,
        address: null,
        town: null,
        email: null,
        email_notification: true,
        phone_number: null,
        phone_numbers_enabled: true,
        phone_number_1: null,
        phone_number_2: null,
        phone_number_3: null,
        phone_number_4: null,
        phone_number_5: null,
        phone_number_6: null,
        phone_number_7: null,
        phone_number_8: null,
        
        note: null,
        registration_number_1: null,
        registration_number_2: null,
        registration_number_3: null,
        registration_number_4: null,
        registration_numbers_enabled: true,
        restriction_start_date: null,
        restriction_end_date: null,
        restriction_start_time: null,
        restriction_end_time: null,
        use_time_restriction: false,
        restriction_time_period: null,
        restriction_id: null,
        user_state: false,
        userTypeInfoText: 'Izberite tip uporabnika',
        user_type: null,

        selected_companies: [],
        companies: [],

        company_id: null,
        truck: false,
        restricted_access: false,

        card_id: null,
        card_number: null,
        card_scan_input: null,
        card_blocked: false,
        card_number_input_enabled: false,
        card_id_input_enabled: false,
        card_type_id: null,
        card_entrance_limit: null,
        card_entrance_quota: null,
        reservation: null,
        room_number: null,
        parcel_number: null,

        restrictionTypes: [],
        userTypes: [],
        cardTypes: [],
        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],
        use_time_restriction_flag: false,

        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        email_rules: [v => !!v || 'Polje email je obvezno'],
        phone_number_rules: [v => !!v || 'Polje za vnos telefonske številke je obvezno'],
        user_type_rules: [v => !!v || 'Polje za izbiro tipa uporabnika je obvezno'],
        company_rules: [v => !!v || 'Polje za vnos podjetja je obvezno'],
        reservation_rules: [v => !!v || 'Polje za vnos rezervacije je obvezno'],

        currentDate: null,

        card_id_rules: [
            v => (!!v) || 'Vnos ID kartice je obvezen!',
        ],
        

        card_type_rules: [
            v => (!!v) || 'Vnos tipa kartice je obvezen!',
        ]


    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        btnSubmitFormTitle() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return 'Shrani'
            }

            return 'Dodaj uporabnika'
        },

        userStateParking() {
            if(this.user_state) {
                return 'DA'
            }

            return 'NE'
        },

        cardBlocked() {
            if(this.card_blocked) {
                return 'DA'
            }

            return 'NE'
        },

        getCardNumber() {
           if(this.card_number == null || this.card_number == '') return false

           return true
        },
        getCardNumber2() {
           if(this.card_number_2 == null || this.card_number_2 == '') return false

           return true
        },

        useTimeRestriction() {
            if(this.use_time_restriction) {
                return 'DA'
            }

            return 'NE'
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        cardIdValue() {
            return this.card_id
        },

        cardIdValue2() {
            return this.card_id_2
        },

        userRegistrationNumbersEnabled() {
            if(this.registration_numbers_enabled) {
                return 'DA'
            }

            return 'NE'
        },

        usePhoneNumbersEnabled() {
            if(this.phone_numbers_enabled) {
                return 'DA'
            }

            return 'NE'
        },

        truckLable() {
            if(this.truck) {
                return "Tovorno vozilo: DA"
            }

            return "Tovorno vozilo: NE"
        },

        restrictedAccessLabel() {
            if(this.restricted_access) {
                return "Omejen dostop: DA"
            }

            return "Omejen dostop: NE"
        }
    },

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        restriction_start_date_formatted(val) {
            if(val == null) {
                this.restriction_start_date = null
            }
        },

        restriction_end_date_formatted(val) {
            if(val == null) {
                this.restriction_end_date = null
            }
        },

        registration_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_1 = this.registration_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_1 = this.registration_number_1.toUpperCase();
            }
        },

        registration_number_2(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_2 = this.registration_number_2.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_2 = this.registration_number_2.toUpperCase();
            }
        },

        registration_number_3(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_3 = this.registration_number_3.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_3 = this.registration_number_3.toUpperCase();
            }
        },

        registration_number_4(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_4 = this.registration_number_4.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_4 = this.registration_number_4.toUpperCase();
            }
        },

    },

    methods: {
        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }

            if(card === 'rfid-card') {
                this.card2 = !this.card2
            }

            if(card === 'user-state') {
                this.card4 = !this.card4
            }

            if(card === 'date-restrictions') {
                this.card5 = !this.card5
            }
            
            if(card === 'restrictions') {
                this.card6 = !this.card6
            }

            if(card === 'time-restrictions') {
                this.card7 = !this.card7
            }
        },

        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        submitForm() {
            var vm = this
            if(this.$refs.form.validate()) {

                this.backendValidationMessages = []
                this.backendMessages = []
                this.backendErrorMessages = []

                let truckVehicle = (this.truck) ? 1 : 0
                let restrictedAccess = (this.restricted_access) ? 1 : 0

                vm.$store.commit('SET_PROGRESS', true)
                vm.storeUserLoading = true
                vm.storeUserLoading2 = true
                vm.$store.commit('SET_LOADER_TEXT', 'Kreiranje uporabnika je v teku...')
                vm.$store.commit('SET_LOADER', true)
                vm.$store.dispatch('CREATE_RFID_USER', {
                    company_id: vm.company_id,
                    user_type: vm.user_type,
                    restriction_id: vm.restriction_id,
                    name: vm.name,
                    address: vm.address,
                    town: vm.town,
                    email: vm.email,
                    email_notification: vm.email_notification,
                    phone_number: vm.phone_number,
                    note: vm.note,
                    reservation: vm.reservation,
                    room_number: vm.room_number,
                    parcel_number: vm.parcel_number,
                    card_id: vm.card_id,
                    card_number: vm.card_number,
                    card_scan_input: vm.card_scan_input,
                    card_blocked: vm.card_blocked,
                    phone_number_1: vm.phone_number_1,
                    phone_number_2: vm.phone_number_2,
                    phone_number_3: vm.phone_number_3,
                    phone_number_4: vm.phone_number_4,
                    phone_number_5: vm.phone_number_5,
                    phone_number_6: vm.phone_number_6,
                    phone_number_7: vm.phone_number_7,
                    phone_number_8: vm.phone_number_8,
                    phone_numbers_enabled: vm.phone_numbers_enabled,
                    registration_number_1: vm.registration_number_1,
                    registration_number_2: vm.registration_number_2,
                    registration_number_3: vm.registration_number_3,
                    registration_number_4: vm.registration_number_4,
                    registration_numbers_enabled: vm.registration_numbers_enabled,
                    restriction_start_date: vm.restriction_start_date,
                    restriction_end_date: vm.restriction_end_date,
                    restriction_start_time: vm.restriction_start_time,
                    restriction_end_time: vm.restriction_end_time,
                    use_time_restriction: vm.use_time_restriction,
                    restriction_time_period: vm.restriction_time_period,
                    truck: truckVehicle,
                    restricted_access: restrictedAccess
                    //user_state: vm.user_state
                })
                .then(response => {
                    //window.console.log(response)
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarText', "Uporabnik je bil uspešno vnesen v sistem.")
                    vm.$store.commit('snackbarMode', '')
                    vm.$store.commit('snackbarTimeout', 7000)
                    vm.$store.commit('snackbarDisplay', true)

                    vm.clearForm()
                    vm.backendMessages.push('Uporabnik je bil uspešno vnesen v sistem.');

                })
                .catch(error => {
                    console.log('CREATE RFID USER error')
                    console.log(error)
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarMode', 'multi-line')
                    vm.$store.commit('snackbarText', 'Pri vnašanju uporabnika v sistem je prišlo do napake. Prosimo poskusite ponovno.')
                    vm.$store.commit('snackbarDisplay', true)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            setTimeout(() => {
                                vm.$vuetify.goTo('#form_card', {
                                    duration: 1000,
                                    offset: 0,
                                    easing: 'easeInOutCubic'
                                })
                            }, 200)
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri zapisovanju uporabnika je prišlo do napake. Poskusite ponovno.')
                        setTimeout(() => {
                            vm.$vuetify.goTo('#form_card', {
                                duration: 1000,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }, 200)
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.storeUserLoading = false;
                        vm.storeUserLoading2 = false;
                        vm.$store.commit('SET_PROGRESS', false)
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    },600)
                })

            }
            else
            {
                setTimeout(() => {
                    vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                }, 200)
            } 
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.setDefaultFormValues()
            this.clearBackendMessages()
            this.restriction_start_date = getCurrentDate()
            this.restriction_end_date = getCurrentDate()
            
        },

        setDefaultFormValues() {
            this.company_id = null
            this.user_type = null
            this.name = null
            this.address = null
            this.town = null
            this.email = null,
            this.email_notification = false,
            this.phone_number = null
            this.phone_number_1 = null
            this.phone_number_2 = null
            this.phone_number_3 = null
            this.phone_number_4 = null
            this.phone_number_5 = null
            this.phone_number_6 = null
            this.phone_number_7 = null
            this.phone_number_8 = null
            this.note = null
            this.reservation = null
            this.room_number = null
            this.parcel_number = null
             this.phone_numbers_enabled = true
            this.registration_number_1 = null
            this.registration_number_2 = null
            this.registration_number_3 = null
            this.registration_number_4 = null
            this.registration_numbers_enabled = true
            this.restriction_start_date = null
            this.restriction_start_date = this.currentDate
            this.restriction_end_date = this.currentDate
            this.restriction_end_time = null
            this.use_time_restriction = false
            this.restriction_start_time = null;
            this.restriction_end_time = null;
            this.restriction_time_period = null
            this.restriction_id = null
            this.card_id = null
            this.card_number = null
            this.card_scan_input = null
            this.card_blocked = false
            this.user_state = false
            this.card_id_input_enabled = false
            this.truck = false
            this.restricted_access = false


            if(this.userTypes.length > 0) {
                //this.user_type = this.userTypes[0].uuid
            }
        },

        RfidNr() {
            let data = this.card_number
            this.card_scan_input = this.card_number
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.scanRfidLoading = true
            this.$store.dispatch('CONVERT_RFID_NR', {nr: data})
            .then(response => {
               // window.console.log(response.data)
               // window.console.log(response.data.rfidNr)
                this.card_number = response.data.rfidNr
                this.scanRfidLoading = false
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Kartica uspešno poskenirana. Serijska številka kartice: " +  response.data.rfidNr + ". Vpišite še ID kartice.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

                this.card_id_input_enabled = true
                setTimeout(() => {
                        vm.$refs.cardId.focus()
                }, 350)

            })
            .catch(error => {
                window.console.log('RfidNr error')
                window.console.log(error)
                this.scanRfidLoading = false    
                this.removeRfidCard()
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', 'Pri skeniranju kartice je prišlo do napake. Poskusite ponovno.')
                vm.$store.commit('SET_SNACKBAR_MODE', '')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

            })
            .finally(() => {
                vm.$store.commit('SET_PROGRESS', false)
            })

            this.card_number_input_enabled = false
        },

        RfidNr3() {
            let data = this.card_number
            this.card_scan_input = this.card_number
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.scanRfidLoading = true

            this.scanRfidLoading = false
            vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
            vm.$store.commit('SET_SNACKBAR_TEXT', "Kartica uspešno poskenirana. Serijska številka kartice: " +  this.card_scan_input + ". Vpišite še ID kartice.")
            vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
            vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
            vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

            this.card_id_input_enabled = true
            setTimeout(() => {
                    vm.$refs.cardId.focus()
            }, 350)

            this.card_number_input_enabled = false
        },

        removeRfidCard() {
            this.card_id = null;
            this.card_number = null;
            this.card_scan_input = null;
            this.card_blocked = null;
            //this.card_type_id = null;
            this.card_id_input_enabled = false;
            this.card_number_input_enabled = false;
            //this.$refs.form.validate();
            this.$refs.cardId.blur();
            this.$refs.cardNumber.blur();

        },

        enableRfidCardInput() {
            this.removeRfidCard()
            this.card_number_input_enabled = true

            setTimeout(() => {
                this.$refs.cardNumber.focus()
            }, 350)
        },

        rfidCardSet()
        {
            if(this.card_id != null && this.card_id != '' && this.card_number != null && this.card_number != "")
            {
                return true
            }

            return false
        },

        restrictionTime1Y() {
            let resStartDate = process.env.VUE_APP_RESTRICTION_START_DATE_1Y;
            let resEndDate = process.env.VUE_APP_RESTRICTION_END_DATE_1Y

            if(resStartDate !== "" && resStartDate !== null && resEndDate !== "" && resEndDate !== null) {
                this.restriction_start_date = resStartDate
                this.restriction_end_date = resEndDate
            }

        },

        restrictionTime5Y() {
            let resStartDate = process.env.VUE_APP_RESTRICTION_START_DATE_5Y;
            let resEndDate = process.env.VUE_APP_RESTRICTION_END_DATE_5Y

            if(resStartDate !== "" && resStartDate !== null && resEndDate !== "" && resEndDate !== null) {
                this.restriction_start_date = resStartDate
                this.restriction_end_date = resEndDate
            }
        },

        restrictionTime10Y() {
            let resStartDate = process.env.VUE_APP_RESTRICTION_START_DATE_10Y;
            let resEndDate = process.env.VUE_APP_RESTRICTION_END_DATE_10Y

            if(resStartDate !== "" && resStartDate !== null && resEndDate !== "" && resEndDate !== null) {
                this.restriction_start_date = resStartDate
                this.restriction_end_date = resEndDate
            }
        },

        restrictionTime1YearFromNow() {
            let d = new Date();
            let year = d.getFullYear();
            let month =  d.getUTCMonth();
            let day = String(d.getDate()).padStart(2, '0')

            let startDate = year + "-" + getMonth2Digits(month) + "-" + getDay2Digits(day);
            let endDate = year + 1 + "-" + getMonth2Digits(month) + "-" + getDay2Digits(day); 

            //window.console.log("Year: ", year);
            //window.console.log("Month: ", month)
            //window.console.log("Day: ", day)

            //window.console.log("Start date: " + startDate)
            //window.console.log("End date: " + endDate)

            this.restriction_start_date = startDate;
            this.restriction_end_date = endDate;
        },

        restrictionTime5YearsFromNow() {
            let d = new Date();
            let year = d.getFullYear();
            let month =  d.getUTCMonth();
            let day = String(d.getDate()).padStart(2, '0')

            let startDate = year + "-" + getMonth2Digits(month) + "-" + getDay2Digits(day);
            let endDate = year + 5 + "-" + getMonth2Digits(month) + "-" + getDay2Digits(day); 

            //window.console.log("Year: ", year);
            //window.console.log("Month: ", month)
            //window.console.log("Day: ", day)

            //window.console.log("Start date: " + startDate)
            //window.console.log("End date: " + endDate)

            this.restriction_start_date = startDate;
            this.restriction_end_date = endDate;
        },

        restrictionTime10YearsFromNow() {
            let d = new Date();
            let year = d.getFullYear();
            let month =  d.getUTCMonth();
            let day = String(d.getDate()).padStart(2, '0')

            let startDate = year + "-" + getMonth2Digits(month) + "-" + getDay2Digits(day);
            let endDate = year + 10 + "-" + getMonth2Digits(month) + "-" + getDay2Digits(day); 

            //window.console.log("Year: ", year);
            //window.console.log("Month: ", month)
            //window.console.log("Day: ", day)

            //window.console.log("Start date: " + startDate)
            //window.console.log("End date: " + endDate)

            this.restriction_start_date = startDate;
            this.restriction_end_date = endDate;
        },

        abonent() {
            return true;
        }


    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
        this.currentDate = getCurrentDate()
    },

    mounted() {
    //this.$store.dispatch('RESTRICTION_TYPES')
    this.$store.dispatch('PARAMETERS_DATA')
        .then(response => {
            //console.log(response.data)
            this.restrictionTypes = response.data.restrictionTypes
            this.userTypes = response.data.userTypes
            //this.companies = response.data.companies
            
            if(this.userTypes.length > 0) {
                //this.user_type = this.userTypes[0].uuid
            }


        })
        .catch(error => {
            console.log("error")
            console.log(error)
        })

        this.restriction_start_date = this.currentDate
        //this.restriction_end_date = new Date().getFullYear() + "-12-31";
        this.restriction_end_date = this.currentDate;

        this.restrictionTime1YearFromNow()
    },



    destroyed() {

    }
}

</script>

<style scoped>
    .btn_rfid {
        margin-right:10px;
        margin-bottom: 15px;
    }
</style>